import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 720.000000 669.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,669.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M1865 5327 c-3 -7 -4 -144 -3 -306 l3 -294 140 -87 c77 -49 145 -93
152 -99 7 -6 15 -11 17 -11 3 0 38 -22 78 -48 40 -27 119 -78 176 -113 56 -35
121 -76 145 -91 23 -14 48 -27 54 -27 10 -1 13 50 15 206 3 207 3 207 26 213
13 3 393 3 845 0 771 -5 825 -6 872 -24 68 -25 107 -54 154 -112 77 -97 105
-237 76 -383 -39 -202 -140 -291 -359 -316 -56 -6 -96 -23 -154 -65 -40 -28
-803 -520 -807 -520 -15 0 -113 -79 -103 -82 7 -3 184 -3 393 0 414 5 725 -8
820 -34 226 -61 329 -215 328 -489 0 -94 -4 -123 -28 -195 -24 -73 -36 -92
-89 -146 -46 -46 -76 -67 -121 -84 -110 -41 -296 -63 -620 -75 -277 -11 -244
-1 -422 -118 -87 -56 -312 -202 -500 -322 -188 -121 -342 -223 -343 -227 0
-12 1660 -9 1834 2 82 5 176 15 208 20 134 25 339 110 456 190 100 69 223 194
278 285 117 191 174 401 174 640 0 301 -78 528 -240 696 -79 82 -221 181 -303
213 -53 20 -55 31 -11 55 120 66 215 152 285 259 93 142 129 286 129 519 0
157 -13 254 -48 353 -102 289 -312 471 -657 568 -203 58 -318 62 -1658 62
-947 0 -1189 -3 -1192 -13z"/>
<path d="M1860 4143 l0 -308 -28 -22 c-15 -12 -36 -25 -47 -29 -11 -4 -34 -17
-51 -30 -17 -13 -34 -24 -38 -24 -4 0 -14 -7 -22 -14 -12 -13 -271 -178 -427
-273 -28 -17 -57 -37 -64 -42 -7 -6 -15 -11 -18 -11 -4 0 -21 -11 -38 -24 -18
-13 -48 -32 -67 -43 -19 -10 -60 -34 -90 -54 -30 -19 -68 -43 -83 -53 -16 -10
-27 -20 -24 -22 2 -3 230 -4 505 -4 329 0 503 -3 507 -10 3 -5 2 -10 -4 -10
-11 0 -9 -1036 1 -1115 18 -130 80 -267 153 -336 46 -44 125 -105 140 -108 6
-1 30 -13 55 -25 72 -36 97 -39 120 -16 11 11 23 20 26 20 3 0 26 14 51 30 24
17 48 30 52 30 5 0 14 6 20 13 6 8 31 24 56 37 25 13 45 27 45 32 0 4 5 8 10
8 10 0 98 56 127 80 7 5 41 26 77 46 37 20 66 40 66 45 0 5 4 9 9 9 10 0 110
62 235 146 l77 52 -36 4 c-22 2 -44 -2 -57 -11 -20 -13 -21 -13 -15 3 6 15 2
16 -28 10 -54 -10 -229 24 -295 57 -51 25 -100 78 -116 124 -11 32 -14 126
-14 450 0 226 4 416 8 423 6 9 31 12 79 11 59 -1 78 2 108 21 28 17 34 25 23
32 -9 6 -6 7 10 3 12 -3 22 -2 22 3 0 5 25 21 56 37 31 16 61 33 67 40 7 6 37
25 68 43 31 18 62 39 69 47 7 8 17 15 22 15 5 0 29 14 53 30 24 17 47 30 51
30 4 0 21 11 39 24 17 13 51 36 76 50 24 13 43 30 41 36 -1 7 3 10 10 8 7 -3
44 17 83 42 38 26 86 55 105 65 18 10 39 23 46 29 6 6 34 25 62 41 29 17 52
32 52 35 0 10 -1110 9 -1115 -1 -4 -5 -13 -3 -21 5 -9 10 -14 36 -14 78 l0 63
-61 40 c-34 22 -80 52 -103 67 -23 14 -48 27 -56 27 -8 1 -21 10 -28 21 -7 11
-17 20 -22 20 -14 0 -93 52 -103 67 -4 7 -12 13 -17 13 -5 0 -33 14 -62 31
-29 18 -58 32 -65 32 -7 0 -10 3 -8 7 2 3 -46 37 -106 74 -60 37 -112 72 -115
77 -19 31 -23 -22 -24 -298z m1573 -510 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m-1853 -23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m1715 -70 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m-1930 -50 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10
19 12 23 12 15 0z m1432 -246 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m-220 -946 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m193
-148 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m236 -135 c-11 -8 -24 -15 -30 -15 -5 0 -1 7 10 15 10 8 24 14 29 14 6 0 1 -6
-9 -14z m-106 -60 c-7 -8 -17 -12 -23 -8 -6 4 -3 9 9 14 27 11 28 11 14 -6z
m-933 -97 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m653 -78 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-53
-42 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
